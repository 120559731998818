<template>
    <div class="billing-summary-container flex-column">
        <p class="billing-summary-header default-text">Total</p>
        <div class="row pb4 flex-column">
           <div class="col flex-row ai-c jc-sb">
              <p class="label default-text">Course cost</p>
              <!-- <p class="amount default-text">{{formatValue(getCoursePrice) || '£0.00'}}</p> -->
              <p class="amount default-text">{{ '£0.00' }}</p>
           </div>
           <div class="col flex-row ai-c jc-sb">
              <p class="label default-text">Service fee</p>
              <p class="amount default-text">{{formatValue(serviceFee) || '£0.00'}}</p>
           </div>
        </div>
        <div class="row flex-column">
           <div class="col flex-row ai-c jc-sb">
              <p class="label default-text">Sub total</p>
              <p class="amount default-text">{{getSubtotal}}</p>
           </div>
           <div class="col flex-row ai-c jc-sb">
              <p class="label default-text">VAT (20%)</p>
              <p class="amount default-text">{{getVat || '£0.00'}}</p>
           </div>
        </div>
        <div class="last-row flex-column">
           <div class="col flex-row ai-c jc-sb">
              <p class="label bold default-text">Total</p>
              <p class="amount bold default-text">{{getTotal ? $filters.formatToCurrency('9.99') : '£0.00'}}</p>
           </div>
           <el-button
            type="primary"
            class="button-cta yellow-button"
            @click="onPay()"
            :loading="isLoading"
            :disabled="isLoading" round>{{ isLoading ? 'PROCESSING...' : 'PAY' }}</el-button>

           <!-- <el-button type="primary" class="button-cta active flex-row ai-c jc-c" @click="onPay()" round v-else>
               BUY WITH
               <inline-svg :src="require(`@/assets/icons/a-payment-white.svg`)" v-if="getPaymentMethod === 'apple-pay'"></inline-svg>
               <inline-svg :src="require(`@/assets/icons/g-payment.svg`)" v-else></inline-svg>
            </el-button> -->
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import $axios from '@/core/utils/axios-api-config';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';
import { OPPORTUNITY_BILLING_STORE } from '@/store/modules/opportunity-billing';
import { USERS_STORE } from '@/store/modules/users';

import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from '../../../config';

function initializeStripe() {
  let stripe = null;

  if ('Stripe' in window) {
    const { Stripe } = window;

    // eslint-disable-next-line no-new
    stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
  }

  return stripe;
}

export default defineComponent({
  data() {
    return {
      isLoading: false,
      redirectUrl: '',

      // fixed service fee
      serviceFee: 8.33
    };
  },
  computed: {
    ...mapGetters(OPPORTUNITY_BILLING_STORE, ['getCoursePrice', 'getServiceCharge', 'getPaymentMethod']),

    ...mapGetters(USERS_STORE, ['user']),

    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse']),

    getSubtotal() {
      return this.formatValue(this.subTotalFormula());
    },

    getVat() {
      if (this.getSubtotal !== '£0.00') {
        return this.formatValue(this.vatFormula());
      }
      return false;
    },

    getTotal() {
      if (this.getSubtotal !== '£0.00') {
        return this.formatValue(this.totalFormula());
      }
      return false;
    }
  },

  async created() {
    if (!this.currentCourse) {
      this.routeToCourses();

      return;
    }

    const host = `${window.location.protocol}//${window.location.host}`;
    this.redirectUrl = `${host}/opportunity/course/${this.currentCourse.id}/calendar`;

    await this.initializeUserState();
  },

  methods: {
    ...mapActions(OPPORTUNITY_BILLING_STORE, ['setCoursePrice']),

    ...mapActions(['setPaymentSuccessfulModal']),

    ...mapActions(USERS_STORE, ['initializeUserState']),

    routeToCourses() {
      this.$router.push({ name: 'opportunity/courses' });
    },

    formatValue(val) {
      return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'GBP' }).format(val);
    },
    subTotalFormula() {
      // return this.getCoursePrice + this.getServiceCharge;
      // free for now
      return this.serviceFee;
    },
    vatFormula() {
      return this.subTotalFormula() * 0.20;
    },
    totalFormula() {
      return this.subTotalFormula() + this.vatFormula();
    },
    routeToCoursePage() {
      this.setPaymentSuccessfulModal(true);
      this.$router.push({ name: 'opportunity' });
    },

    async onPay() {
      this.isLoading = true;

      setTimeout(() => {
        this.initializeStripePayment();
      }, 500);
    },

    async initializeStripePayment() {
      this.stripe = initializeStripe();

      if (this.stripe) {
        await this.onCheckout();
      }
    },

    async createSession() {
      const { currentCourse } = this;
      const { id: userId, userStripeCustomer, email } = this.user;
      const courseId = currentCourse.id;

      let customer = { customerId: '' };
      const productName = `${currentCourse.about}-course-${courseId}`;

      const entity = JSON.stringify({ id: courseId, name: 'course' });

      if (!userStripeCustomer.length) {
        // tmp, needs to be transferred to service
        await $axios.post(`/payment/${userId}/customer`, {
          email,
          entity
        }).then((customerResponse) => {
          customer = customerResponse;
        })
          .catch((e) => {
            this.$notify.error({
              message: e || 'Error occured, please try again'
            });
          });
      } else {
        const [stripeCustomer] = userStripeCustomer; // 1-1 customer only for now
        customer = stripeCustomer;
      }

      const { customerId } = customer;

      if (customerId) {
        await $axios.post(`/payment/${userId}/session`, {
          customerId,
          price: 9.99, // fixed price
          redirectUrl: this.redirectUrl,
          productName,
          metadata: JSON.stringify({
            productName,
            courseId
          }),
          oneTimePayment: true
        }).then(async (sessionResponse) => {
          if (sessionResponse) {
            this.sessionId = sessionResponse.id;
          }
        });
      }
    },

    async onCheckout() {
      await this.createSession();

      if (this.sessionId) {
        this.stripe.redirectToCheckout({
          sessionId: this.sessionId,
        }).then((result) => {
          if (result.error) {
            this.$notify.error({
              message: result.error || 'Error.'
            });
          }
        });
      }
    },

  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;
.default-text {
    font-family: Mulish;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
    display: flex;
    gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.jc-sb {
    justify-content: space-between;
}
.jc-c {
    justify-content: center;
}
.billing-summary-container {
    gap: 1rem;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
    border-radius: 8px;
    padding: 2.2rem 2rem;
    box-sizing: border-box;
    height: 400px;
}
.billing-summary-header {
    font-size: 18px;
    font-weight: 700;
}
.row {
    width: 100%;
    padding-bottom: 1rem;
    border-bottom:  2px solid rgba(12, 15, 74, 0.1);
    gap: .5rem;
}
.pb4 {
    padding-bottom: 4rem;
}
.col {}
.label{
}
.amount {
    font-weight: 700;
}
.bold {
    font-size: 14px;
    font-weight: 700;
}
.gray {
    color: rgba(12, 15, 74, 0.5);
}
.button-cta {
    height: 42px;
    border-radius: 8px;
    background: rgba(12, 15, 74, 0.1);
    font-family: Mulish;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    color: rgba(12, 15, 74, 0.5);
    font-family: Mulish;
    border: none;

    &:hover {
        background: rgba(12, 15, 74, 0.1);
        color: rgba(12, 15, 74, 0.5);
    }
}

.button-cta.yellow-button {
  width: 100%;
  border-radius: 7px;
  background: #FAA200;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  letter-spacing: 1.25px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.active {
    background: #000000;
    color: #FFFFFF;

    :deep(span) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .7rem;
    }

    &:hover {
        background: #000000;
        color: #FFFFFF;
    }
}
@include media-sm-max-width {
  .billing-summary-container {
    background: rgba(255, 255, 255, 1);
    box-shadow: none;
    .billing-summary-header {
      display: none;
    }
  }
}
</style>
