<template>
    <div class="billing-info-container flex-column">
        <p class="billing-header default-text">Items</p>
        <div class="row flex-row jc-sb ai-c">
            <div class="label-container flex-row ai-c">
               <p class="label default-text">{{ currentCourse?.title }}</p>
            </div>
            <!-- <p class="billing-amount default-text">
                {{formatValue(getCoursePrice) || '£0.00'}}
            </p> -->
            <p class="billing-amount default-text">
                {{ '£0.00' }}
            </p>
        </div>
        <div class="row flex-row jc-sb ai-c">
            <div class="label-container flex-row ai-c">
               <p class="label default-text">Service fee</p>
               <el-icon :size="17" color="rgba(12, 15, 74, 0.3)"><InfoFilled/></el-icon>
            </div>
            <p class="billing-amount default-text">
                {{formatValue(getServiceCharge) || '£0.00'}}
            </p>
        </div>
        <!-- <BillingPaymentMethodComponent/> -->
    </div>
</template>
<script>
import { InfoFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

// import BillingPaymentMethodComponent from '@/modules/opportunity-billing/components/BillingPaymentMethodComponent.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';
import { OPPORTUNITY_BILLING_STORE } from '@/store/modules/opportunity-billing';

export default defineComponent({
  components: {
    InfoFilled,
    // BillingPaymentMethodComponent
  },
  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),
    ...mapGetters(OPPORTUNITY_BILLING_STORE, ['getCourseDetails', 'getCoursePrice', 'getServiceCharge', 'getPaymentMethod'])
  },
  methods: {
    formatValue(val) {
      return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'GBP' }).format(val);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;
.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
    display: flex;
    gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.jc-sb {
    justify-content: space-between;
}
.jc-c {
    justify-content: center;
}

.billing-info-container {
  gap: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
  border-radius: 8px;
  padding: 2.2rem 2rem;
  box-sizing: border-box;
}
.billing-header {
    font-size: 18px;
    line-height: 22px;
}
.row {
    width: 100%;
    padding-bottom: 1rem;
    border-bottom:  2px solid rgba(12, 15, 74, 0.1);
}

@include media-sm-max-width {
  .billing-info-container {
    box-shadow: none
  }
}
</style>
